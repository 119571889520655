<template>
	<main class="blog">
		<HeroSection v-bind="pageConfig.heroConfig" />

		<BlogPostsListingSection class="posts-listing-padding" />
	</main>
</template>

<script setup lang="ts">
import type {
	WebsitePageWithName,
	WebsitePagesByName
} from '@SHARED/core/entities/WebsiteConfig';

import HeroSection from '@/components/sections/HeroSection.vue';
import BlogPostsListingSection from '@/components/sections/BlogPostsListingSection.vue';

definePageMeta({
	name: 'BlogListing',
	pageTitle: 'Blog'
});

const pagesByName = useState<WebsitePagesByName>('pagesByName');

const pageConfig = computed<WebsitePageWithName<'BlogListing'>>(
	() => pagesByName.value.BlogListing
);
</script>

<style lang="postcss" scoped>
.posts-listing-padding {
	padding-top: 3rem;
	padding-bottom: 3rem;
}
</style>
